<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>휘슬 사용자 충전 계약</h2>
		<p>본 Whistle 사용자 재충전 계약(이하 "본 계약")은 귀하와 CLICK ADVERTISING & MEDIA PTE. LTD.(이하 "회사") 사이에 체결되며 본 계약은 계약적 효력을 갖습니다. 귀하의 정당한 권리와 이익을 보호하기 위해 본 계약의 모든 조항, 특히 회사의 책임을 면제하거나 제한하는 조항(이하 "면책 조항")에 동의하고 동의하기 전에 각 조항의 내용을 주의 깊게 읽고 완전히 이해하시기 바랍니다. '), 귀하의 권리를 제한하는 조항(이하 '제한 조항'), 분쟁 해결 방법 및 관할권을 규정하는 조항. 앞서 언급한 면책 조항, 제한 사항, 분쟁 해결 방법 및 기타 중요한 용어는 굵게 표시되거나 기타 합리적인 방식으로 강조 표시되어 이러한 조건을 확인하면 특정 상황에서 귀하가 수동적이고 불편하며 손실을 입을 수 있음을 상기시킵니다. 본 계약에 대한 동의를 확인하기 전 또는 당사의 소프트웨어 서비스를 사용하기 전에 이전 조항을 준수하십시오. </p>
		<p>만 18세 미만인 경우, 법정대리인과 함께 본 약관을 읽어보시고, 당사가 제공하는 서비스 이용 및 당사에 대한 요금지불에 대해 보호자의 동의 및 승인을 받으시기 바랍니다. 회사의 소프트웨어 서비스는 본 약관의 "개인정보 보호정책", "아동 이용약관" 및 "아동 개인정보 보호정책"의 모든 조항을 준수한 후에만 제공됩니다. 미성년자의 법정대리인인 경우. 14세(포함) 미만의 미성년자 또는 법정대리인인 경우. </p>
		<p>본 계약이나 당사의 소프트웨어 서비스에 대한 의견이나 제안 사항이 있는 경우 본 계약에 동의한 대로 당사의 고객 서비스 부서에 문의하시면 도움을 드리겠습니다. 귀하가 "동의" 버튼, "동의" 버튼을 클릭하거나 당사 서비스를 설치, 사용, 등록 또는 기타 방식으로 액세스하면 귀하는 본 계약의 전체 내용을 자세히 읽고 이해한 것으로 간주되며 본 조항을 준수하는 데 동의합니다. 계약.따라서 클릭하기 전에 본 계약을 주의 깊게 완전히 읽으십시오. </p>
		<h2>제1조 서비스 이용규칙</h2>
		<p>1. "스타빈"은 본 플랫폼에서의 관련 소비를 위해 본 플랫폼이 귀하에게 제공하는 가상 소품입니다. 귀하는 "스타빈"을 사용하여 본 플랫폼에서 가상 선물 및 기타 상품이나 서비스를 구매할 수 있습니다. 상품 및 서비스에 대해서는 해당 상품 및 서비스 페이지 설명을 확인하시고, 구매한 가상 선물을 앵커 또는 플랫폼 창작자에게 전달해주세요. 단, '스타빈'은 어떠한 경우에도 RMB로 교환할 수 없으므로 반드시 실제 필요량에 따라 해당 수량만큼 '스타빈'을 구매하시기 바랍니다. </p>
		<p>2. 충전 과정에서 계정을 주의 깊게 확인하고 관련 작업 옵션을 주의 깊게 선택하십시오. 계좌번호의 부정확한 입력, 부적절한 운용, 충전 및 청구방법에 대한 이해부족, 이해부족 등의 요인으로 인해 귀하의 권익이 훼손되는 경우 귀하의 권익이 훼손되는 것은 귀하의 몫입니다. 책임은 본인에게 있으며 본 플랫폼은 어떠한 보상이나 보상도 제공하지 않습니다. </p>
		<p>3. 충전은 반드시 본 플랫폼에서 지정한 공식 충전방법을 이용하시기 바랍니다. 본 플랫폼에서 명시하지 않은 충전 방법이나 불법적인 충전 방법을 사용하는 경우, 본 플랫폼은 충전이 원활하고 정확하게 완료될 것을 보장하지 않으며, 이로 인해 다른 위험이 발생할 수 있습니다. 이로 인해 이익이 훼손된 경우 귀하는 책임을 져야 합니다.본 플랫폼은 처리에 대해 어떠한 보상이나 배상도 하지 않으며 상응하는 책임을 집니다.동시에 본 플랫폼은 귀하의 계정 자격의 일부 또는 전부를 정지할 권리가 있습니다. , 계정 잔액 동결, 계정을 단기 또는 영구적으로 금지하고 언제든지 다양한 서비스 이용을 금지하는 서비스를 충전할 수 있는 권리입니다. </p>
		<p>4. "성두" 충전은 비즈니스 운영상의 필요에 따라 본 플랫폼에서 제공하는 일회성 서비스이며, "성두"는 이유 없이 반환될 수 있는 보증금, 선불 또는 기타 상품이나 재산권이 아닙니다. 사용자의 충전이 성공한 후 플랫폼의 충전 서비스가 제공되면 플랫폼은 더 이상 역교환, 이체 서비스를 제공하지 않으며 정당한 이유 없이 플랫폼은 환불 서비스를 제공하지 않습니다. "Xingdou" 및 "Xingdou"와 관련된 모든 기능과 서비스를 제공하거나 법률 및 규정에 따라 이 플랫폼을 반환해야 한다고 명시되어 있는 경우), 특정 작업을 수행하기 전에 반드시 신중하게 선택하시기 바랍니다. </p>
		<p>5. 플랫폼이 시스템 장애 또는 기타 사유로 인해 처리 오류를 발견한 경우, 그것이 플랫폼에게 이익이 되는지 또는 귀하에게 이익이 되는지 여부에 관계없이 플랫폼은 이메일, 문자 메시지 또는 기타 방법으로 귀하에게 통지한 후 이를 정정할 권리가 있습니다. 기타 합리적인 수단 오류. 본 조치로 인해 귀하가 수령해야 하는 “스타빈”의 실제 수량보다 실제 수령하는 “스타빈”의 수가 적은 경우, 플랫폼은 처리 오류를 확인한 후 가능한 한 빨리 귀하의 계정에 차액을 보상해 드립니다. 오류로 인해 귀하가 실제로 받아야 하는 것보다 더 많은 "스타빈"을 받게 되는 경우, 플랫폼은 오류의 성격과 원인에 관계없이 귀하의 계정에서 직접 차액을 공제할 권리가 있습니다. </p>
		<p>6. 회사의 상품 기능에 따라, 회사가 운영하는 다른 상품 및 플랫폼(이하 "제휴 플랫폼"이라 함)에서 귀하의 충전계정으로 구매한 가상 소품은 귀하가 구매한 "스타빈"과 결합될 수 있습니다. 이 플랫폼에서는 동일한 사용 범위를 갖습니다. 이때 귀하가 이 플랫폼에서 관련 플랫폼의 가상 소품을 사용하는 경우에도 본 계약이 적용됩니다(앞서 언급한 관련 플랫폼의 가상 소품이 "Xingdou" 또는 기타 이름으로 불리는지 여부에 관계 없음). (본 플랫폼상의 이름), 반대로, 귀하의 스타빈이 위의 방법을 통해 관련 플랫폼에서 사용되는 경우, 귀하가 관련 플랫폼에서 "스타빈"을 사용하는 경우에도 관련 플랫폼 충전 계약 및 관련 규칙 및 약관이 적용됩니다. </p>
		<p>7. 각 충전 방식에 해당하는 채널 제공자는 자체 기준에 따라 해당 충전 채널 처리 수수료를 설정하여 사용자 충전 시 이를 청구할 수 있습니다. 각 충전 방식별 채널 제공자 서비스 수수료를 반드시 확인하신 후 하나를 선택하시기 바랍니다. 귀하의 필요에 따라 재충전 방법. </p>
		<p>8. "Xingdou"는 구매 후 RMB로 역전환될 수 없으며, 플랫폼은 본 약관에 규정된 정당한 사유 없이 환불 서비스를 제공하지 않습니다. </p>
		<h2>제2조 권리 선언</h2>
		<p>1. 귀하는 이 플랫폼이 거래 보안 및 기타 고려사항(거래 한도, 거래 횟수 등을 포함하되 이에 국한되지 않음)을 기반으로 수시로 거래 관련 사항을 설정할 권리가 있음을 이해하고 이에 동의합니다. </p>
		<p>2. 충전 과정에 제3자가 제공하는 관련 서비스가 포함되는 경우, 본 계약을 준수하는 것 외에도 제3자의 계약 및 관련 규칙에도 동의하고 준수해야 합니다. 어떤 경우에도 이 제3자의 경우 제3자 및 그에 의해 제공되는 관련 서비스와 관련하여 발생한 분쟁은 귀하와 제3자가 직접 해결해야 하며, 본 플랫폼은 이에 대해 귀하 또는 제3자에 대해 어떠한 책임도 지지 않습니다. </p>
		<p>3. 귀하의 사유로 인해 이 플랫폼이 "Xingdou" 구매 서비스를 제공할 수 없거나 "Xingdou" 구매 서비스 제공 오류로 인해 발생하는 모든 손실이나 책임은 전적으로 귀하의 책임이며 이 플랫폼은 어떠한 책임도 지지 않습니다. . 다음을 포함하되 이에 국한되지 않는 책임:</p>
		<ul>
		<li>• (1) 헌법이 정한 기본 원칙에 반대하는 행위</li>
		<li>• (2) 귀하의 Whistle 계정이 유효하지 않거나 분실되었거나 차단된 경우</li>
		<li>• (3) 인증되지 않은 제3자 결제 계좌 사용 또는 본인 소유가 아닌 제3자 결제 계좌 사용을 포함하여 귀하와 결합된 제3자 결제 기관 계좌로 인해 발생한 손실 또는 책임. 결제 계좌가 동결되거나 압수된 경우,</li>
		<li>• (4) 타인에게 비밀번호를 알려줌으로써 발생한 재산 피해</li>
		<li>• (5) 귀하의 개인적인 고의 또는 중과실로 인해 발생한 재산 피해. </li>
		</ul>
		<p>4. 다음 조건으로 인해 플랫폼의 충전 서비스를 이용할 수 없거나 가상 재산을 잃어버린 경우, 귀하는 플랫폼이 다음을 포함하되 이에 국한되지 않는 손해에 대해 책임을 지지 않는다는 데 동의합니다.</p>
		<h2>휘슬 사용자 충전 계약</h2>
		<p>본 Whistle 사용자 재충전 계약(이하 "본 계약")은 귀하와 CLICK ADVERTISING & MEDIA PTE. LTD.(이하 "회사") 사이에 체결되며 본 계약은 계약적 효력을 갖습니다. 귀하의 정당한 권리와 이익을 보호하기 위해 본 계약의 모든 조항, 특히 회사의 책임을 면제하거나 제한하는 조항(이하 "면책 조항")에 동의하고 동의하기 전에 각 조항의 내용을 주의 깊게 읽고 완전히 이해하시기 바랍니다. '), 귀하의 권리를 제한하는 조항(이하 '제한 조항'), 분쟁 해결 방법 및 관할권을 규정하는 조항. 앞서 언급한 면책 조항, 제한 사항, 분쟁 해결 방법 및 기타 중요한 용어는 굵게 표시되거나 기타 합리적인 방식으로 강조 표시되어 이러한 조건을 확인하면 특정 상황에서 귀하가 수동적이고 불편하며 손실을 입을 수 있음을 상기시킵니다. 본 계약에 대한 동의를 확인하기 전 또는 당사의 소프트웨어 서비스를 사용하기 전에 이전 조항을 준수하십시오. </p>
		<p>만 18세 미만인 경우, 법정대리인과 함께 본 약관을 읽어보시고, 당사가 제공하는 서비스 이용 및 당사에 대한 요금지불에 대해 보호자의 동의 및 승인을 받으시기 바랍니다. 회사의 소프트웨어 서비스는 본 약관의 "개인정보 보호정책", "아동 이용약관" 및 "아동 개인정보 보호정책"의 모든 조항을 준수한 후에만 제공됩니다. 미성년자의 법정대리인인 경우. 14세(포함) 미만의 미성년자 또는 법정대리인인 경우. </p>
		<p>본 계약이나 당사의 소프트웨어 서비스에 대한 의견이나 제안 사항이 있는 경우 본 계약에 동의한 대로 당사의 고객 서비스 부서에 문의하시면 도움을 드리겠습니다. 귀하가 "동의" 버튼, "동의" 버튼을 클릭하거나 당사 서비스를 설치, 사용, 등록 또는 기타 방식으로 액세스하면 귀하는 본 계약의 전체 내용을 자세히 읽고 이해한 것으로 간주되며 본 조항을 준수하는 데 동의합니다. 계약.따라서 클릭하기 전에 본 계약을 주의 깊게 완전히 읽으십시오. </p>
		<h2>제1조 서비스 이용규칙</h2>
		<p>1. "스타빈"은 본 플랫폼에서의 관련 소비를 위해 본 플랫폼이 귀하에게 제공하는 가상 소품입니다. 귀하는 "스타빈"을 사용하여 본 플랫폼에서 가상 선물 및 기타 상품이나 서비스를 구매할 수 있습니다. 상품 및 서비스에 대해서는 해당 상품 및 서비스 페이지 설명을 확인하시고, 구매한 가상 선물을 앵커 또는 플랫폼 창작자에게 전달해주세요. 단, '스타빈'은 어떠한 경우에도 RMB로 교환할 수 없으므로 반드시 실제 필요량에 따라 해당 수량만큼 '스타빈'을 구매하시기 바랍니다. </p>
		<p>2. 충전 과정에서 계정을 주의 깊게 확인하고 관련 작업 옵션을 주의 깊게 선택하십시오. 계좌번호의 부정확한 입력, 부적절한 운용, 충전 및 청구방법에 대한 이해부족, 이해부족 등의 요인으로 인해 귀하의 권익이 훼손되는 경우 귀하의 권익이 훼손되는 것은 귀하의 몫입니다. 책임은 본인에게 있으며 본 플랫폼은 어떠한 보상이나 보상도 제공하지 않습니다. </p>
		<p>3. 충전은 반드시 본 플랫폼에서 지정한 공식 충전방법을 이용하시기 바랍니다. 본 플랫폼에서 명시하지 않은 충전 방법이나 불법적인 충전 방법을 사용하는 경우, 본 플랫폼은 충전이 원활하고 정확하게 완료될 것을 보장하지 않으며, 이로 인해 다른 위험이 발생할 수 있습니다. 이로 인해 이익이 훼손된 경우 귀하는 책임을 져야 합니다.본 플랫폼은 처리에 대해 어떠한 보상이나 배상도 하지 않으며 상응하는 책임을 집니다.동시에 본 플랫폼은 귀하의 계정 자격의 일부 또는 전부를 정지할 권리가 있습니다. , 계정 잔액 동결, 계정을 단기 또는 영구적으로 금지하고 언제든지 다양한 서비스 이용을 금지하는 서비스를 충전할 수 있는 권리입니다. </p>
		<p>4. "성두" 충전은 비즈니스 운영상의 필요에 따라 본 플랫폼에서 제공하는 일회성 서비스이며, "성두"는 이유 없이 반환될 수 있는 보증금, 선불 또는 기타 상품이나 재산권이 아닙니다. 사용자의 충전이 성공한 후 플랫폼의 충전 서비스가 제공되면 플랫폼은 더 이상 역교환, 이체 서비스를 제공하지 않으며 정당한 이유 없이 플랫폼은 환불 서비스를 제공하지 않습니다. "Xingdou" 및 "Xingdou"와 관련된 모든 기능과 서비스를 제공하거나 법률 및 규정에 따라 이 플랫폼을 반환해야 한다고 명시되어 있는 경우), 특정 작업을 수행하기 전에 반드시 신중하게 선택하시기 바랍니다. </p>
		<p>5. 플랫폼이 시스템 장애 또는 기타 사유로 인해 처리 오류를 발견한 경우, 그것이 플랫폼에게 이익이 되는지 또는 귀하에게 이익이 되는지 여부에 관계없이 플랫폼은 이메일, 문자 메시지 또는 기타 방법으로 귀하에게 통지한 후 이를 정정할 권리가 있습니다. 기타 합리적인 수단 오류. 본 조치로 인해 귀하가 수령해야 하는 “스타빈”의 실제 수량보다 실제 수령하는 “스타빈”의 수가 적은 경우, 플랫폼은 처리 오류를 확인한 후 가능한 한 빨리 귀하의 계정에 차액을 보상해 드립니다. 오류로 인해 귀하가 실제로 받아야 하는 것보다 더 많은 "스타빈"을 받게 되는 경우, 플랫폼은 오류의 성격과 원인에 관계없이 귀하의 계정에서 직접 차액을 공제할 권리가 있습니다. </p>
		<p>6. 회사의 상품 기능에 따라, 회사가 운영하는 다른 상품 및 플랫폼(이하 "제휴 플랫폼"이라 함)에서 귀하의 충전계정으로 구매한 가상 소품은 귀하가 구매한 "스타빈"과 결합될 수 있습니다. 이 플랫폼에서는 동일한 사용 범위를 갖습니다. 이때 귀하가 이 플랫폼에서 관련 플랫폼의 가상 소품을 사용하는 경우에도 본 계약이 적용됩니다(앞서 언급한 관련 플랫폼의 가상 소품이 "Xingdou" 또는 기타 이름으로 불리는지 여부에 관계 없음). (본 플랫폼상의 이름), 반대로, 귀하의 스타빈이 위의 방법을 통해 관련 플랫폼에서 사용되는 경우, 귀하가 관련 플랫폼에서 "스타빈"을 사용하는 경우에도 관련 플랫폼 충전 계약 및 관련 규칙 및 약관이 적용됩니다. </p>
		<p>7. 각 충전 방식에 해당하는 채널 제공자는 자체 기준에 따라 해당 충전 채널 처리 수수료를 설정하여 사용자 충전 시 이를 청구할 수 있습니다. 각 충전 방식별 채널 제공자 서비스 수수료를 반드시 확인하신 후 하나를 선택하시기 바랍니다. 귀하의 필요에 따라 재충전 방법. </p>
		<p>8. "Xingdou"는 구매 후 RMB로 역전환될 수 없으며, 플랫폼은 본 약관에 규정된 정당한 사유 없이 환불 서비스를 제공하지 않습니다. </p>
		<h2>제2조 권리 선언</h2>
		<p>1. 귀하는 이 플랫폼이 거래 보안 및 기타 고려사항(거래 한도, 거래 횟수 등을 포함하되 이에 국한되지 않음)을 기반으로 수시로 거래 관련 사항을 설정할 권리가 있음을 이해하고 이에 동의합니다. </p>
		<p>2. 충전 과정에 제3자가 제공하는 관련 서비스가 포함되는 경우, 본 계약을 준수하는 것 외에도 제3자의 계약 및 관련 규칙에도 동의하고 준수해야 합니다. 어떤 경우에도 이 제3자의 경우 제3자 및 그에 의해 제공되는 관련 서비스와 관련하여 발생한 분쟁은 귀하와 제3자가 직접 해결해야 하며, 본 플랫폼은 이에 대해 귀하 또는 제3자에 대해 어떠한 책임도 지지 않습니다. </p>
		<p>3. 귀하의 사유로 인해 이 플랫폼이 "Xingdou" 구매 서비스를 제공할 수 없거나 "Xingdou" 구매 서비스 제공 오류로 인해 발생하는 모든 손실이나 책임은 전적으로 귀하의 책임이며 이 플랫폼은 어떠한 책임도 지지 않습니다. . 다음을 포함하되 이에 국한되지 않는 책임:</p>
		<ul>
		<li>• (1) 헌법이 정한 기본 원칙에 반대하는 행위</li>
		<li>• (2) 귀하의 Whistle 계정이 유효하지 않거나 분실되었거나 차단된 경우</li>
		<li>• (3) 인증되지 않은 제3자 결제 계좌 사용 또는 본인 소유가 아닌 제3자 결제 계좌 사용을 포함하여 귀하와 결합된 제3자 결제 기관 계좌로 인해 발생한 손실 또는 책임. 결제 계좌가 동결되거나 압수된 경우,</li>
		<li>• (4) 타인에게 비밀번호를 알려줌으로써 발생한 재산 피해</li>
		<li>• (5) 귀하의 개인적인 고의 또는 중과실로 인해 발생한 재산 피해. </li>
		</ul>
		<p>4. 다음 조건으로 인해 플랫폼의 충전 서비스를 이용할 수 없거나 가상 재산을 잃어버린 경우, 귀하는 플랫폼이 다음을 포함하되 이에 국한되지 않는 손해에 대해 책임을 지지 않는다는 데 동의합니다.</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/RechargeAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/RechargeAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/RechargeAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/RechargeAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>